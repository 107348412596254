<template>
    <div class="page_box">
        <div class="top_box">
            <div class="top_title">搜索栏</div>
            <div class="top_flex">
                <div class="search_flex">
                    <div class="texts">关键字</div>
                    <div class="inputs_box"><input type="text" placeholder="姓名/手机号/员工号/证件号码" v-model="forms.keyword">
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">离职类型</div>
                    <div class="inputs_box">
                        <el-select v-model="forms.type" placeholder="请选择">
                            <el-option v-for="(item, index) in lzlx_list" :key="index" :label="item.title" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">离职原因</div>
                   <div class="inputs_box">
                        <el-select v-model="forms.reason" placeholder="请选择">
                            <el-option v-for="(item, index) in lzyy_list" :key="index" :label="item.title" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">离职时间</div>
                    <div class="inputs_box">
                        <el-date-picker v-model="forms.time" type="date" placeholder="请选择出生日期"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="search_flex">
                    <div class="texts">单位</div>
                    <div class="inputs_box">
                        <el-select v-model="forms.job_title" placeholder="请选择" clearable >
                            <el-option v-for="(item, index) in dw_lsit" :key="index" :label="item.title" :value="item.title">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="inputs_box"><input type="text" placeholder="请输入单位" v-model="forms.job_title">
                    </div> -->
                </div>
                <div class="search_flex">
                    <div class="texts">岗位</div>
                    <div class="inputs_box">
                        <el-select v-model="forms.gw_title" placeholder="请选择" clearable >
                            <el-option v-for="(item, index) in gw_lsit" :key="index" :label="item.title" :value="item.title">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="inputs_box"><input type="text" placeholder="请输入岗位" v-model="forms.gw_title">
                    </div> -->
                </div>
                <!-- <div class="search_flex">
                    <div class="texts">时间范围</div>
                    <div class="inputs_box">
                        <el-date-picker :editable="false" 
                        value-format="yyyy-MM-dd" :default-time="['00:00:00','23:59:59']" v-model="times" type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                    </div>
                </div> -->
                <div class="search_flex">
                    <div class="btn_ends" @click="handle_search">搜索</div>
                </div>
            </div>

        </div>
        <div class="bom_box">
            <div class="bom_btn_flex">
                <div class="lefts">
                    {{ page_title }}
                </div>
                <div class="rights">
                   
                    <div class="btns"@click="handle_dc">
                        导出
                    </div>
                </div>
            </div>
            <div class="bom_box_list">
                <el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
                    @selection-change="handle_list_change" v-loading="loading" height="465">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="name" label="姓名" width="150">
                        <template slot-scope="scope">
							<div slot="reference" class="name-wrapper" style="color: #2373C8;">
                                {{ scope.row.name }}
                            </div>
						</template>
                    </el-table-column>
                    <el-table-column prop="job_title" label="单位" width="100"></el-table-column>
                    <el-table-column prop="gw_title" label="岗位"></el-table-column>
                    <el-table-column prop="time" label="离职时间"></el-table-column>
                    <el-table-column prop="type" label="离职类型"></el-table-column>
                    <el-table-column prop="reason" label="离职原因"></el-table-column>
                    <el-table-column prop="order_no" label="文号" width="150"></el-table-column>
                    <el-table-column prop="pdf.name" label="文件名"width="200">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top" :content="scope.row.pdf.name">
								<div slot="reference" class="name-wrapper2">
									<a :href="scope.row.pdf.path" target="_blank" style="color:#FF5E00">
										{{ scope.row.pdf.name }}
									</a>
								</div>
							</el-popover>
						</template>
                    </el-table-column>
                    <el-table-column prop="pdf_time" label="文件日期"></el-table-column>
                    <!-- 操作模块   -->
                    <!-- fixed="right"  -->
                    <!-- <el-table-column label="操作" fixed="right"  width="100">
                        <template slot-scope="scope">
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link">
                                    <div class="btn_img">
                                        <img src="../../assets/img/employee/caozuo.png" alt="">
                                    </div>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{ key: 1, row: scope.row }">查看详情</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 2, row: scope.row }">编辑信息</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 3, row: scope.row }">离职操作</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 4, row: scope.row }">加入黑名单</el-dropdown-item>
                                    <el-dropdown-item :command="{ key: 5, row: scope.row }">添加简历</el-dropdown-item>

                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="pages">
                    <page :pageson='count' @row="row" @currentPage="currentPage"></page>
                </div>
            </div>
        </div>
        <Employee_Information_add ref="Employee_Information_add"></Employee_Information_add>
        <Employee_Information_dimission ref="Employee_Information_dimission"></Employee_Information_dimission>
    </div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的

import Employee_Information_add from "@/components/Employee_management/Employee_Information_add.vue"; //导入员工信息
import Employee_Information_dimission from "@/components/Employee_management/Employee_Information_dimission.vue"; //离职


export default {
    components: {
        page,
        Employee_Information_add,
        Employee_Information_dimission,


    },
    data() {
        return {
            loading: false,//加载总
            page_title: '',//页面名称  来之路由
            check_list: [],//选中的
            //提交数据
            forms: {
                keyword: '',
                type:'',
                reason:'',
                time:'',
                job_title:'',
                gw_title:'',
            },
            list: [
                 
            ],
            pages: {
                page: 1,
                limit: 10,
            },
            count: 0,
            times: [],//时间范围
            item_row: {},//选中的
            // 岗位状态
            zwzt_list: [
                {
                    title: '在岗',
                },
                {
                    title: '离职',
                },
                {
                    title: '黑名单',
                },
            ],
            props: {
                    multiple: true,
                },
            lzlx_list: [
                {
                    title:'全部',
                    value:'',
                },
                {
                    title:'辞职',
                    value:'辞职',
                },
                {
                    title:'调动',
                    value:'调动',
                },
                {
                    title:'解除',
                    value:'解除',
                },
                {
                    title:'终止',
                    value:'终止',
                },
                {
                    title:'退休',
                    value:'退休',
                },
                {
                    title:'死亡',
                    value:'死亡',
                },
                {
                    title:'其他',
                    value:'其他',
                },
            ],
            lzyy_list:[
                {
                    title:'全部',
                    value:'',
                },
                {
                    title:'个人原因',
                    value:'个人原因',
                },
                {
                    title:'其他',
                    value:'其他',
                },
            ],
            dw_list:[],
            gw_lsit:[],

        };
    },

    methods: {
        //接受条数
        currentPage(page) {
            console.log(page)
            this.pages.page = page
            this.get_list()
        },
        // 接收条数
        row(row) {
            console.log(row)
            this.pages.limit = row
            this.get_list()
        },
        //选择
        handle_list_change(e) {
            console.log('多选', e)
            this.check_list = e
        },
        //搜素
        handle_search() {
            this.pages.page = 1
            this.get_list()
        },
        //表单回调 触发重新渲染数据
        handle_get_list() {
            this.get_list()
        },
       
      
       
        // 导出
        handle_dc(){},

        //操作回调
        handleCommand(e) {
            // console.log(e)
            this.item_row = e.row
            let i = e.key
            switch (i) {
                //查看详情
                case 1:
                    this.handle_ckxq()
                    break;
                // 编辑信息
                case 2:
                    this.handle_bjxx()
                    break;
                // 离职操作
                case 3:
                    this.handle_lzcz()
                    break;
                // 加入黑名单
                case 4:
                    this.handle_jrhmd()
                    break;
                // 添加简历
                case 5:
                    this.handle_tjjl()
                    break;
            }
        },
        //查看详情
        handle_ckxq() { },
        //编辑信息
        handle_bjxx() { },
        //离职操作
        handle_lzcz() {
            this.$refs.Employee_Information_dimission.handle_open()
         },
        //加入黑名单
        handle_jrhmd() { },
        //添加简历
        handle_tjjl() { },
        //统一的列表接口
        get_list() {
            console.log(this.forms)
            this.loading = true
            this.$api("getUserDimission", {
                // mobile:this.mobile
                ...this.forms,
                ...this.pages,
            }, "get").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.count = res.data.count
                    this.list = res.data.list
                    this.list.forEach((e)=>{
                        try {
                            if(e.pdf){
                                e.pdf=JSON.parse(e.pdf)
                            }
                        } catch (error) {
                            e.pdf={}
                        }
                       
                    })
                } else {
                    alertErr(res.msg)
                }
            });
        },
        //获取岗位
        get_gw_list(){
            this.loading = true
            this.$api("getCategoryAllList", {
                
            }, "get").then((res) => {
                console.log('所有的岗位',res)
                this.loading = false
                if (res.code == 200) {
                    let level=0
                    let list=[]
                    let data=res.data
                    data.forEach((e,i)=>{
                        if(e.level>level){
                            level=e.level
                        }
                        this.$set(e,'children',[])
                    })
                    console.log('最高登记',level)

                    for (let i = 1; i <= level; i++) {
                        let level_list=[]
                        data.forEach((e)=>{
                            if(e.level==i){
                                level_list.push(e)
                            }
                        })
                        list.push(level_list)
                    }
                    console.log('列表',list)
                    this.dw_lsit=list[0]

                    this.gw_lsit=list[list.length-1]

                    
                    
                } else {
                    alertErr(res.msg)
                }
            });
        },

    },
    computed: {
    },
    watch: {
    },
    created() {
        this.page_title = this.$route.meta.title
        this.get_list()
        this.get_gw_list()
    },
    mounted() {

    }


};
</script>

<style scoped lang="less">
.page_box {
    // min-height: 100%;

    // background-color: red;
    .top_box {
        width: 100%;
        // height: 80px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding-bottom: 9px;

        .top_title {
            padding: 10px 17px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
        }

        .top_flex {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 60px;

            .search_flex {
                // width: 25%;
                display: flex;
                align-items: center;
                margin-right: 48px;
                margin-bottom: 20px;

                .texts {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    white-space: nowrap;
                    padding-right: 12px;
                    width: 100px;
                }

                .inputs_box {
                    width: 236px;

                    input {
                        width: 236px;
                        height: 40px;
                        background: #FFFFFF;
                        border-radius: 0px 0px 0px 0px;
                        border: 1px solid #E8E8E8;
                        padding: 0 9px;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #747474;

                    }

                    /deep/.el-select {
                        width: 236px;

                    }
                }


                .btn_ends {
                    width: 80px;
                    height: 40px;
                    background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
                    border-radius: 2px 2px 2px 2px;
                    text-align: center;
                    line-height: 40px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

                    }
                }

            }

            .search_flex:nth-child(4n) {
                margin-right: 0;
            }
        }




    }

    .bom_box {
        background: #FFFFFF;

        .bom_btn_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 51px;
            background: #FFFFFF;
            box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
            border-radius: 4px 4px 4px 4px;
            margin-top: 20px;
            padding: 0 20px;

            .lefts {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: bold;
                font-size: 14px;
                color: #333333;
            }

            .rights {
                display: flex;
                align-items: center;

                .btns {
                    background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
                    padding: 6px 13px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    margin-left: 16px;

                    &:hover {
                        background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

                    }
                }

            }
        }

        .bom_box_list {
            width: 1640px;
            // height: 670px;
            padding: 20px 20px 32px 20px;

            .pages {
                padding-top: 30px;
            }
        }

    }
}
</style>